<template>
  <div class="tenantManagement">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="addTenant"></v-button>
      </template>
      <template #searchSlot>
        <tenantAreaSelect
          @changeModel="changeModel"
          specifyCollectionTypes="0"
        ></tenantAreaSelect>
      </template>
      <template #operateSlot="{ row }">
        <v-button text="编辑" type="text" @click="editData(row)" />
        <v-button
          text="短信通道"
          type="text"
          @
          @click="handelClickSmsChannel(row)"
        />
        <v-button
          text="运营配置"
          type="text"
          @
          @click="handelClickConfig(row)"
        />
        <v-button text="删除" type="text" @click="deleteData(row)" />
      </template>
    </v-list>
    <v-dialog
      title="短信通道配置"
      v-model="isDialog"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="保存"
    >
      <form-panel
        labelWidth="200px"
        :form="form"
        :hasHeader="false"
        :footerShow="false"
      >
        <el-form-item
          label="服务地址"
          prop="lvchengSendUrl"
          :rules="[
            {
              required: true,
              message: '请输入服务地址',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="form.lvchengSendUrl"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="通道类型"
          prop="channelCode"
          :rules="[
            {
              required: true,
              message: '请选择通道类型',
              trigger: 'blur',
            },
          ]"
        >
          <v-select
            :width="width"
            :options="channelCodeOps"
            v-model="form.channelCode"
            placeholder="请选择"
          ></v-select>
        </el-form-item>
        <el-form-item
          label="通道开关"
          prop="isOpen"
          :rules="[
            {
              required: true,
              message: '请选择通道开关',
              trigger: 'blur',
            },
          ]"
        >
          <v-radio :radioObj="channelSwitch" v-model="form.isOpen"></v-radio>
        </el-form-item>
        <el-form-item
          label="账号"
          prop="lvchengUsername"
          :rules="[
            {
              required: true,
              message: '请输入账号',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="form.lvchengUsername"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="lvchengPassword"
          :rules="[
            {
              required: true,
              message: '请输入密码',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="form.lvchengPassword"
          ></v-input>
        </el-form-item>
      </form-panel>
    </v-dialog>
    <v-dialog
      title="运营配置"
      v-model="isShowConfigDialog"
      @confirm="confirmConfig"
      cancelTxt="取消"
      sureTxt="保存"
    >
      <form-panel :form="configForm" :hasHeader="false" :footerShow="false">
        <el-form-item
          label="opsAppId"
          prop="opsAppId"
          :rules="[
            {
              required: false,
              message: '请输入opsAppId',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="configForm.opsAppId"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="opsAppSecret"
          prop="opsAppSecret"
          :rules="[
            {
              required: false,
              message: '请输入opsAppSecret',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="configForm.opsAppSecret"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="szdzAppKey"
          prop="szdzAppKey"
          :rules="[
            {
              required: false,
              message: '请输入szdzAppKey',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="configForm.szdzAppKey"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="szdzAppSecret"
          prop="szdzAppSecret"
          :rules="[
            {
              required: false,
              message: '请输入szdzAppSecret',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="configForm.szdzAppSecret"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="channelCode 【渠道码】"
          prop="channelCode"
          :rules="[
            {
              required: false,
              message: '请输入channelCode 【渠道码】',
              trigger: 'blur',
            },
          ]"
        >
          <v-input
            placeholder="请输入"
            :width="width"
            v-model="configForm.channelCode"
          ></v-input>
        </el-form-item>
      </form-panel>
    </v-dialog>
  </div>
</template>

<script>
import {
  getDTenantWithVList,
  deleteDTenant,
  getSmsConfigById,
  updateSmsConfig,
  updateTenantConfig,
  getTenantConfigById,
} from "./api.js";
import tenantAreaSelect from "./components/tenantAreaSelect/index.vue";
import { channelCodeOps, channelSwitch } from "./map.js";
export default {
  components: {
    tenantAreaSelect,
  },
  name: "tenantManagement",
  data() {
    return {
      width: 350,
      id: "",
      isDialog: false,
      isShowConfigDialog: false,
      searchParam: {},
      tableUrl: getDTenantWithVList,
      channelSwitch: channelSwitch, //通道开关数据
      form: {
        id: "",
        lvchengSendUrl: "", //服务地址
        channelCode: "", //通道类型
        isOpen: 0, //通道开关
        lvchengUsername: "", //账号
        lvchengPassword: "", //密码
      },
      // 运营配置提交数据
      configForm: {
        tenantId: "",
        opsAppId: "",
        opsAppSecret: "",
        szdzAppKey: "",
        szdzAppSecret: "",
        channelCode: "",
        wxModuleId: "",
        cockpitModuleId: "",
      },
      channelCodeOps: channelCodeOps(), //通道类型
      headers: [
        {
          prop: "areaName",
          label: "区",
        },
        {
          prop: "streetName",
          label: "街道",
        },
        {
          prop: "districtName",
          label: "社区",
        },
        {
          prop: "villageName",
          label: "小区",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value.spaceId;
    },
    //确认保存
    confirm() {
      this.form.id = this.id;
      this.$axios.post(updateSmsConfig, this.form).then((res) => {
        if (res.code == 200) {
          this.isDialog = false;
          this.$message.success(res.msg);
        }
      });
    },
    //确认保存运营配置
    confirmConfig() {
      this.$axios.post(updateTenantConfig, this.configForm).then((res) => {
        if (res.code == 200) {
          this.isShowConfigDialog = false;
          this.$message.success(res.msg);
        }
      });
    },
    //编辑
    editData(row) {
      this.$router.push({
        name: "addTenantManagement",
        query: {
          parentId: row.tenantId,
          areaName: row.areaName,
          districtName: row.districtName,
          streetName: row.streetName,
        },
      });
    },
    //新增
    addTenant() {
      this.$router.push({
        name: "addTenantManagement",
      });
    },
    //短信通道
    handelClickSmsChannel(row) {
      this.id = row.id;
      const params = {
        id: row.id,
      };
      this.$axios.get(getSmsConfigById, { params }).then((res) => {
        if (res.code == 200) {
          this.isDialog = true;
          const { data } = res;
          Object.keys(this.form).forEach((key) => {
            this.form[key] = data[key];
          });
        }
      });
    },
    //运营配置
    handelClickConfig(row) {
      const params = {
        id: row.id,
      };
      this.$axios.get(getTenantConfigById, { params }).then((res) => {
        if (res.code == 200) {
          this.isShowConfigDialog = true;
          const { data } = res;
          Object.keys(this.configForm).forEach((key) => {
            this.configForm[key] = data[key];
          });
          if (!data?.tenantId) {
            this.configForm.tenantId = row.tenantId;
          }
        }
      });
    },
    //删除
    deleteData(row) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          tenantIds: row.tenantId,
        };
        this.$axios
          .post(deleteDTenant, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.$refs.list.getList();
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tenantManagement {
  box-sizing: border-box;
  height: 100%;
  ::v-deep .el-dialog__body {
    padding: 30px 50px;
    width: 100%;
    box-sizing: border-box;
    .form-panel-container {
      width: 100%;
    }
    .content {
      width: 100%;
      .el-form {
        width: 100%;
        .el-form-item {
          width: 100%;
          .el-form-item__label {
            width: 30% !important;
          }
          .el-form-item__content {
            margin: 0 !important;
            width: 70% !important;
            .v-control {
              width: 100%;
              .el-input {
                width: 100% !important;
              }
              .el-select {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
