import { mapHelper } from "@/utils/common";
// 通道类型, 
const channelCode = [
  {
    label: "绿城短信通",
    value: 2,
  },
 
];
const { map: channelCodeMap, setOps: channelCodeOps } = mapHelper.setMap(channelCode);
const channelSwitch = [
  {
    name: 0,
    value: "关闭",
  },
  {
    name: 1,
    value: "开启",
  },
];
export {
  channelSwitch,
  channelCodeOps,
  channelCodeMap
};
